import React, { useState } from 'react'
import styled from 'styled-components'
import useInterval from '../hooks/interval'
import Icons from './icoons'
import { randomIndex } from '../utils/number'

function Hero({ titles, suffix, subheading, icons }) {
  const [index, setIndex] = useState(0)

  useInterval(() => {
    if (titles.length === 0) return
    let newIndex = index

    do {
      newIndex = randomIndex(titles.length)
    } while (titles[index] === titles[newIndex])

    setIndex(newIndex)
  }, 2400)

  return (
    <Root>
      <Centered>
        <CenteredContent>
          <Heading>
            <TextAnimation>
              &nbsp;
              {titles.map((title, i) => (
                <span
                  key={i}
                  style={{
                    opacity: index === i ? 1 : 0,
                    transform:
                      index === i ? 'translateY(0)' : 'translateY(20px)'
                  }}
                >
                  {title}
                </span>
              ))}
            </TextAnimation>
            {suffix}
          </Heading>
          <SubHeading>
            <span>{subheading}</span>
          </SubHeading>
          <Icons icons={icons} />
        </CenteredContent>
      </Centered>
    </Root>
  )
}

const Root = styled.div``

const Centered = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const CenteredContent = styled.div`
  padding: 0 10px;
  max-width: 840px;
  margin-bottom: 40px;
`

const Heading = styled.h1`
  margin: 0;
  font-size: calc(60px + (94 - 14) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 800;
  text-shadow: 6px 6px #000;
  color: #d2d2d2;
  line-height: 0.9;
  margin-bottom: 20px;
  @media (min-width: 700px) {
    margin-bottom: 40px;
  }
`

const TextAnimation = styled.span`
  display: block;
  position: relative;

  > span {
    position: absolute;
    top: 0;
    left: 0;
    transition: transform ease-out 300ms, opacity ease 200ms;
  }
`

const SubHeading = styled.div`
  color: #908050;
  @media (min-width: 700px) {
    font-size: 22px;
  }
`

export default Hero

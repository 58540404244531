import React from 'react'
import styled from 'styled-components'

const Icons = ({ icons }) => (
  <Root>
    {icons.map(icon => (
      <a
        key={icon.class}
        href={icon.href}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={icon.ariaLabel}
      >
        <i className={icon.class} />
      </a>
    ))}
  </Root>
)

export default Icons

const Root = styled.div`
  margin-top: 20px;

  i {
    display: inline-block;
    height: 24px;
    width: 24px;
    margin: 8px;
    opacity: 0.6;
    transition: opacity 80ms ease;

    &:hover {
      opacity: 0.2;
    }

    &.linked-in {
      background-image: url('https://res.cloudinary.com/wilbo/image/upload/v1555929081/linkedin_v7mwuw_rcxe5p.svg');
    }
    &.codepen {
      background-image: url('https://res.cloudinary.com/wilbo/image/upload/v1555928917/codepen_bskocm_lar91e.svg');
    }
    &.medium {
      background-image: url('https://res.cloudinary.com/wilbo/image/upload/v1555928992/medium_mczltb_i5xyt0.svg');
    }
    &.twitter {
      background-image: url('https://res.cloudinary.com/wilbo/image/upload/v1555928992/twitter_kqrrab_wtji4o.svg');
    }
    &.github {
      background-image: url('https://res.cloudinary.com/wilbo/image/upload/v1555928992/github_gp3bjv_sdhlqv.svg');
    }
  }
`

import React from 'react'

import Layout from '../layouts/layout'
import SEO from '../components/seo'
import Background from '../components/background'
import Hero from '../components/hero'

const titles = ['Frontend', 'JavaScript', 'TypeScript', 'Frontend', 'React']

const icons = [
  {
    href: 'https://twitter.com/wilboships',
    ariaLabel: "Wilbert's Twitter profile",
    class: 'twitter'
  },
  {
    href: 'https://github.com/wilbo',
    ariaLabel: "Wilbert's Github profile",
    class: 'github'
  },
  {
    href: 'https://medium.com/@wilbo',
    ariaLabel: "Wilbert's Medium profile",
    class: 'medium'
  },
  {
    href: 'https://www.linkedin.com/in/wilbert-schepenaar/',
    ariaLabel: "Wilbert's Linked-in profile",
    class: 'linked-in'
  },
  {
    href: 'https://codepen.io/wilbo/',
    ariaLabel: "Wilbert's Codepen profile",
    class: 'codepen'
  }
]

function IndexPage() {
  return (
    <Layout>
      <SEO />
      <Background />
      <Hero
        titles={titles}
        suffix="Developer"
        icons={icons}
        subheading="I unleash the web's full capability by creating fully featured, intuitive and performant web experiences."
      />
    </Layout>
  )
}

export default IndexPage
